<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    >
      <volver-parte-checklist-menu-items
        slot="menuItemsLongPress"
        :idparte-trabajo="routeParams.idparte_trabajo"
      />
    </b10-toolbar>
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :list-item-lines="3"
        :show-alerts="!routeFromBrowserHistory"
      >
        <template slot="extraSubtitle">
          <span
            v-html="formattedItem.observacionesFormateadas"
          />
        </template>
        <template
          slot="cardActionsLeft"
        >
          <v-btn
            v-show="prevEnabled"
            icon
            color="primary"
            @click="clickSiguienteAnteriorMaterial(false)"
          >
            <v-icon>{{ $vuetify.icons.values.prev }}</v-icon>
          </v-btn>
          <v-btn
            v-show="nextEnabled"
            icon
            color="primary"
            @click="clickSiguienteAnteriorMaterial(true)"
          >
            <v-icon>{{ $vuetify.icons.values.next }}</v-icon>
          </v-btn>
        </template>
      </b10-view-summary>
      <b10-view-details
        :details="details"
        @click-detail="clickDetail"
      />
    </b10-page-content>
    <lparte-trabajo-delete
      :showing.sync="showingDialogs.delete"
      @delete="clickDeleteConfirm"
    />
  </b10-base>
</template>

<script>
import { viewPageMixin } from '@/mixins/viewPageMixin'
import _ from '@/utils/lodash'
import Data from './LparteTrabajoViewData'
import { ARTICULO } from '@/utils/consts'
import VolverParteChecklistMenuItems from '../components/VolverParteChecklistMenuItems'
import colors from 'vuetify/lib/util/colors'
import { PARTE_TRABAJO } from '@/utils/consts'
import LparteTrabajoDelete from '../components/LparteTrabajoDelete'

export default {
  components: {
    VolverParteChecklistMenuItems, LparteTrabajoDelete,
  },
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      toolbarOptions: {
        edit: {
          title: 'Modificar',
          visible: true,
          icon: 'edit'
        },
        delete: {
          title: 'Eliminar',
          visible: true,
          icon: 'delete'
        },
      },
      moreInfoRows: [
        { name: 'lparte_trabajo.codigo', label: 'Código' },
        { name: 'lparte_trabajo.descripcion', label: 'Descripción' },
        { name: 'lparte_trabajo.unidades', label: 'Unidades' },
        { name: 'lparte_trabajo.facturar', label: 'Facturable', filter: this.$options.filters.humanizeBoolean },
        { name: 'tmotivo_nofacturable.descripcion', label: 'Motivo no facturable' },
        { name: 'lparte_trabajo.nserie', label: 'Nº de serie' },
        { name: 'lparte_trabajo.nelemento', label: 'Nº de elemento' },
        { name: 'lparte_trabajo.ubicacion', label: 'Ubicación' },
        { name: 'lparte_trabajo.netiqueta', label: 'Nº de etiqueta' },
        { name: 'lparte_trabajo.numzona', label: 'Nº de zona' },
        { name: 'lparte_trabajo.evento', label: 'Evento' },
        { name: 'lparte_trabajo.funcion', label: 'Función' },
        { name: 'lparte_trabajo.particion', label: 'Partición' },
        // { name: 'lparte_trabajo.fult_revision', label: 'Última revisión', filter: this.$options.filters.shortDate },
        // { name: '', label: 'Próxima revisión', filter: this.$options.filters.shortDate },
        { name: 'lparte_trabajo.fult_retimbrado', label: 'Último retimbrado', filter: this.$options.filters.shortDate },
        // { name: '', label: 'Próximo retimbrado', filter: this.$options.filters.shortDate },
        // { name: 'lparte_trabajo.fcaducidad', label: 'Fecha de caducidad', filter: this.$options.filters.shortDate },
        // { name: 'lparte_trabajo.ffin_garantia', label: 'Fin de garantía', filter: this.$options.filters.shortDate },
        { name: 'fabricante.descripcion', label: 'Fabricante' },
        { name: 'lparte_trabajo.ffabricacion', label: 'Fecha de fabricación', filter: this.$options.filters.shortDate },
        { name: 'agente_extintor.descripcion', label: 'Agente extintor' },
        { name: 'agente_propelente.descripcion', label: 'Agente propelente' },
        { name: 'lparte_trabajo.volumen', label: 'Volumen' },
        { name: 'lparte_trabajo.peso_total', label: 'Peso total' },
        { name: 'lparte_trabajo.peso_agente_extintor', label: 'Peso agente extintor' },
        { name: 'lparte_trabajo.eficacia', label: 'Eficacia' },
        { name: 'lparte_trabajo.presion', label: 'Presión' },
        { name: 'lparte_trabajo.observaciones', label: 'Observaciones' },
        { name: 'articulo.nserie_obligado', label: 'Nº serie obligatorio', filter: this.$options.filters.humanizeBoolean },
        { name: 'almacen.descripcion', label: 'Almacén' },
        { name: 'lparte_trabajo.imputa_al_proyecto', label: 'Imputa al proyecto', filter: this.$options.filters.humanizeBoolean },
      ],
      tsubsis: {},
      showingDialogs: {
        moreInfo: false,
        delete: false,
      },
      nextEnabled: true,
      prevEnabled: true,
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item.dataset) && !_.isEmpty(this.tsubsis)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.lparte_trabajo.idlparte_trabajo
        item.title = item.lparte_trabajo.descripcion
        item.subtitle = this.$offline.lparteTrabajo.resumenFichaTecnica(item)
        if (item.lparte_trabajo.facturar) {
          item.badge = 'Facturable'
          item.badgeColor = colors.green.base
        } else {
          item.badge = 'NO FACTURABLE'
          item.badgeColor = colors.red.base
        }
        item.observacionesFormateadas = this.$options.filters.linebreaksBr(item.lparte_trabajo.observaciones)
        this.setMoreInfoRowValue('lparte_trabajo.nserie', 'visible', this.tsubsis.tsubsis.ver_mat_nserie)
        this.setMoreInfoRowValue('articulo.nserie_obligado', 'visible', this.tsubsis.tsubsis.ver_mat_nserie)
        this.setMoreInfoRowValue('lparte_trabajo.nelemento', 'visible', this.tsubsis.tsubsis.ver_mat_nelemento)
        this.setMoreInfoRowValue('lparte_trabajo.ubicacion', 'visible', this.tsubsis.tsubsis.ver_mat_ubicacion)
        this.setMoreInfoRowValue('lparte_trabajo.netiqueta', 'visible', this.tsubsis.tsubsis.ver_mat_netiqueta)
        this.setMoreInfoRowValue('lparte_trabajo.numzona', 'visible', this.tsubsis.tsubsis.ver_mat_num_zona)
        this.setMoreInfoRowValue('lparte_trabajo.evento', 'visible', this.tsubsis.tsubsis.ver_mat_evento)
        this.setMoreInfoRowValue('lparte_trabajo.funcion', 'visible', this.tsubsis.tsubsis.ver_mat_funcion)
        this.setMoreInfoRowValue('lparte_trabajo.particion', 'visible', this.tsubsis.tsubsis.ver_mat_particion)
        // this.setMoreInfoRowValue('lparte_trabajo.fult_revision', 'visible', this.tsubsis.tsubsis.ver_mat_fult_revision)
        // (no existe aún en offline, es un campo calculado en el servidor)
        // this.setMoreInfoRowValue('', 'visible', this.tsubsis.tsubsis.ver_mat_fprox_revision)
        this.setMoreInfoRowValue('lparte_trabajo.fult_retimbrado', 'visible', this.tsubsis.tsubsis.ver_mat_fult_retimbrado)
        // (no existe aún en offline, es un campo calculado en el servidor)
        // this.setMoreInfoRowValue('', 'visible', this.tsubsis.tsubsis.ver_mat_fprox_retimbrado)
        // this.setMoreInfoRowValue(''visible', lparte_trabajo.fcaducidad', this.tsubsis.tsubsis.ver_mat_fcaducidad)
        // this.setMoreInfoRowValue(''visible', lparte_trabajo.ffin_garantia', this.tsubsis.tsubsis.ver_mat_ffin_garantia)
        this.setMoreInfoRowValue('fabricante.descripcion', 'visible', this.tsubsis.tsubsis.ver_mat_idfabricante)
        this.setMoreInfoRowValue('lparte_trabajo.ffabricacion', 'visible', this.tsubsis.tsubsis.ver_mat_ffabricacion)
        this.setMoreInfoRowValue('agente_extintor.descripcion', 'visible', this.tsubsis.tsubsis.ver_mat_idagente_extintor)
        this.setMoreInfoRowValue('agente_propelente.descripcion', 'visible', this.tsubsis.tsubsis.ver_mat_idagente_propelente)
        this.setMoreInfoRowValue('lparte_trabajo.volumen', 'visible', this.tsubsis.tsubsis.ver_mat_volumen)
        this.setMoreInfoRowValue('lparte_trabajo.peso_total', 'visible', this.tsubsis.tsubsis.ver_mat_peso_total)
        this.setMoreInfoRowValue('lparte_trabajo.peso_agente_extintor', 'visible', this.tsubsis.tsubsis.ver_mat_peso_agente_extintor)
        this.setMoreInfoRowValue('lparte_trabajo.eficacia', 'visible', this.tsubsis.tsubsis.ver_mat_eficacia)
        this.setMoreInfoRowValue('lparte_trabajo.presion', 'visible', this.tsubsis.tsubsis.ver_mat_presion)
        item.moreInfo = this.buildMoreInfo()
        return item
      } else {
        return {}
      }
    },
    estadoPartePendiente () {
      return this.parteTrabajo?.parte_trabajo?.estado === PARTE_TRABAJO.estados.pendiente
    },
  },
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = this.item.dataset.lparte_trabajo.descripcion
      this.tsubsis = await this.$offline.tsubsis.row(this.item.dataset.subsis.idtsubsis)
      this.parteTrabajo = await this.$offline.parteTrabajo.row(this.routeParams.idparte_trabajo)
      this.toolbarOptions.edit.visible = this.estadoPartePendiente
      this.toolbarOptions.delete.visible = this.estadoPartePendiente
      await this.loadDetailTotals()
    },
    async loadItem () {
      this.loadingData = true
      try {
        const dataset = await Data.selectLparteTrabajo(this, this.routeParams.idlparte_trabajo)
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async loadDetailTotals () {
      this.initDetails()
      const datasetEscandallo = await Data.selectLordenTrabajoDetalleCount(
        this, this.routeParams.idlparte_trabajo
      )
      // Si la linea está compuesta por más de una linea.
      if (this.item.dataset.articulo.tipo !== ARTICULO.tipos.simple || datasetEscandallo.count > 1) {
        const detailEscandallo = this.addDetail(
          'escandallo', 'Escandallo', 'Composición del artículo ampliado o plantilla ', 'escandallo'
        )
        detailEscandallo.badge = datasetEscandallo.count || 0
      }
    },
    clickDetail (data) {
      if (data.detail.name === 'escandallo') {
        this.$appRouter.push({
          name: 'offline__lorden-trabajo-detalle-list',
          params: {
            idorden_trabajo: this.item.dataset.parte_trabajo.idorden_trabajo,
            idlorden_trabajo: this.item.dataset.lparte_trabajo.idlorden_trabajo,
          },
        })
      }
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.edit) {
        this.$appRouter.push({
          name: 'offline__lparte-trabajo-edit',
          params: {
            idparte_trabajo: this.routeParams.idparte_trabajo,
            idlparte_trabajo: this.routeParams.idlparte_trabajo,
          }
        })
      } else if (option === this.toolbarOptions.delete) {
        this.showingDialogs.delete = !this.showingDialogs.delete
      }
    },
    async clickDeleteConfirm (data) {
      this.$loading.showManual('Eliminando...')
      try {
        await this.$offline.lparteTrabajo.deleteLparteUpdateUnidadesLordenSync(
          this.routeParams.idlparte_trabajo, data.deleteOT
        )
        await this.$dirty.modified(this.$dirty.ENTITIES.local.lparteTrabajoSubsis, this.item.dataset.lparte_trabajo.idsubsis)
        await this.$dirty.deleted(this.$dirty.ENTITIES.local.lparteTrabajo, this.routeParams.idlparte_trabajo)
        this.$alert.showSnackbarSuccess(
          `Se ha eliminado "${this.item.dataset.lparte_trabajo.descripcion}" del material a instalar/facturar`
        )
        this.$appRouter.go(-1)
      } finally {
        this.$loading.hide()
      }
    },
    async clickSiguienteAnteriorMaterial (siguiente) {
      const materialIrA = await Data.selectSiguienteAnteriorMaterial(
        this,
        this.routeParams.idparte_trabajo,
        this.item.dataset.lparte_trabajo.orden,
        this.item.dataset.lparte_trabajo.idsubsis,
        siguiente
      )
      if (materialIrA) {
        await this.$appRouter.replace({
          name: 'offline__lparte-trabajo-view',
          params: {
            idparte_trabajo: materialIrA.idparte_trabajo,
            idlparte_trabajo: materialIrA.idlparte_trabajo,
          },
        })
      } else {
        const subsisDesc = this.item.dataset.subsis.descripcion
        const lparteTrabajoDesc = this.item.dataset.lparte_trabajo.descripcion
        if (siguiente) {
          this.nextEnabled = false
          this.$alert.showSnackbarInfo(`${lparteTrabajoDesc} es el último material del subsistema ${subsisDesc}`
          )
        } else {
          this.prevEnabled = false
          this.$alert.showSnackbarInfo(`${lparteTrabajoDesc} es el primer material del subsistema ${subsisDesc}`
          )
        }
      }
    },
  }
}
</script>
